import {ActionContext, ActionTree} from 'vuex';
import {RootState} from '@/store/types';
import {adapter} from '@/store/adapter';
import {InlenerCao, InlenerCaoDetail, InlenerDetails} from '@/store/inleners/types';

export const actions: ActionTree<{}, RootState> = {
  inviteInlenerUser(store: ActionContext<{}, RootState>, payload:
    { email: string }): Promise<Array<{ sent_at: string; sent_to_email: string }>> {
    return adapter.postRequest('/inlener/inlener-invites/', payload, true);
  },

  inlenerDetails(
    store: ActionContext<{}, RootState>,
    subscription_id: number,
  ): Promise<InlenerDetails> {
    return adapter.getRequest(`/inlener/inlener-detail/${subscription_id}/`, {}, true);
  },

  inlenerInvites(store, payload): Promise<Array<{ sent_at: string; sent_to_email: string }>> {
    return adapter.getRequest('/inlener/inlener-invites/', payload, true);
  },

  inlenerCaos(
    store: ActionContext<{}, RootState>,
    payload: { page: number, contains: string, only_user: boolean, inlener_subscription_id: number },
  ): Promise<{ caos: InlenerCao[], page: number }> {
    return new Promise((resolve, reject) => {
      adapter.getRequest(`/inlener/inleners-cao-uitzender-list/${payload.inlener_subscription_id}/`, payload, true)
        .then((response: { caos: InlenerCao[], page: number }) => {
          resolve(response);
        })
        .catch(reject);
    });
  },

  caoCompliances(
    store: ActionContext<{}, RootState>,
    payload: { page: number, contains: string, only_user: boolean, inlener_subscription_id: number },
  ): Promise<{ caos: InlenerCao[], page: number }> {
    return new Promise((resolve, reject) => {
      adapter.getRequest(`/inlener/cao-compliance-uitzender-list/${payload.inlener_subscription_id}/`, payload, true)
        .then((response: { caos: InlenerCao[], page: number }) => {
          resolve(response);
        })
        .catch(reject);
    });
  },

  inlenerCaoDetail(store: ActionContext<{}, RootState>, inlenerCaoId: number): Promise<InlenerCaoDetail[]> {
    return new Promise((resolve, reject) => {
      adapter.getRequest('/inlener/inleners-cao-detail/', {inlenerCaoId: inlenerCaoId.toString()}, true)
        .then((response: InlenerCaoDetail[]) => {
          resolve(response);
        }).catch(() => reject());
    });
  },

  inlenerCaoVersions(store: ActionContext<{}, RootState>, inlenerCaoGuid: string): Promise<InlenerCao[]> {
    return new Promise((resolve, reject) => {
      adapter.getRequest(`/inlener/inleners-cao-uitzender-versions/${inlenerCaoGuid}/`, {}, true)
        .then((response: InlenerCao[]) => {
          resolve(response);
        }).catch(() => reject());
    });
  },
};
